<template>
  <div>
  </div>
</template>

<script>
// @ is an alias to /src
import {mapState, mapActions} from 'vuex'

export default {
  name: 'Home',
  props: [
    'redirectPage'
  ],
  computed: {
    ...mapState('users',[
      'hasValidToken'
    ]),
  },
  methods: {
    ...mapActions('users', [
      'checkTokenStatus'
    ]),
  },
  created () {
    this.checkTokenStatus().then(() => {
      if (this.hasValidToken) {
        this.$router.push({name: this.redirectPage}).catch(() => {})
      } else {
        this.$router.replace({name: 'Login'}).catch(() => {})
      }
    })
  },
}
</script>
